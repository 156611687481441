/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Styled } from 'theme-ui'
import styled from '@emotion/styled'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import ContainerLeftTitle from '../components/ContainerLeftTitle'
import Referenzen from '../components/Referenzen'
import Partners from '../components/Partners'
import PostSlider from '../components/PostSlider'
import ArrowSVG from '../images/vectors/arrow-down.inline.svg'
import scrollTo from 'gatsby-plugin-smoothscroll'

const Content = styled.div`
  border-top: 2px solid #000;
  width: 100%;
  max-width: 100%;
`

const Arrow = styled(ArrowSVG)`
  max-width: 100%;
  width: 15px;
  height: auto;
`

const blocks = css`
  display: block;
  float: left;
  width: 100%;
`

const SectionTop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  font-size: ${props => props.theme.fontSizes[2]}px;
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    flex-flow: row wrap;
    font-size: ${props => props.theme.fontSizes[1]}px;
  }
`

const SectionTopLinklist = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > a {
    border-bottom: 2px solid #000;
    font-size: ${props => props.theme.fontSizes[5]}px;
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 30px 60px;
    &:nth-of-type(1) {
      background: ${props => props.theme.colors.red};
      &:hover {
        opacity: 1 !important;
        background: linear-gradient(
          90deg,
          ${props => props.theme.colors.red} 0%,
          ${props => props.theme.colors.background} 100%
        );
      }
    }
    &:nth-of-type(2) {
      background: ${props => props.theme.colors.green};
      &:hover {
        opacity: 1 !important;
        background: linear-gradient(
          90deg,
          ${props => props.theme.colors.green} 0%,
          ${props => props.theme.colors.background} 100%
        );
      }
    }
    &:nth-of-type(3) {
      background: ${props => props.theme.colors.purple};
      &:hover {
        opacity: 1 !important;
        background: linear-gradient(
          90deg,
          ${props => props.theme.colors.purple} 0%,
          ${props => props.theme.colors.background} 100%
        );
      }
    }
    &:nth-of-type(4) {
      background: ${props => props.theme.colors.grey};
      &:hover {
        opacity: 1 !important;
        background: linear-gradient(
          90deg,
          ${props => props.theme.colors.grey} 0%,
          ${props => props.theme.colors.background} 100%
        );
      }
    }
    &:last-of-type {
      border-bottom: none;
    }
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: auto;
    flex-grow: 1;
    border-right: 2px solid #000;
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    > a {
      font-size: ${props => props.theme.fontSizes[3]}px;
      padding: 30px;
    }
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    > a {
      padding: 15px 15px;
    }
  }
`

const SectionTopDescription = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 720px;
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    border-top: 2px solid #000;
  }

  position: relative;

  .ark-arrow {
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: inline-block;

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      right: 30px;
    }

    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      display: none;
    }
  }
`

const SectionTopDescriptionLeft = styled.div`
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 50%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    padding: 30px 30px 30px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding: 30px 15px 30px;
  }

  a {
    display: block;
    margin: 1rem 0;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    border: 2px solid #000;
    text-align: center;
    border-radius: 999px;
    opacity: 1 !important;
    font-size: ${props => props.theme.fontSizes[2]}px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      padding: 10px 10px 10px;
      font-size: ${props => props.theme.fontSizes[1]}px;
      max-width: 250px;
    }
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  h2 {
    font-size: ${props => props.theme.fontSizes[6]}px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      font-size: ${props => props.theme.fontSizes[5]}px;
      line-height: 1.2;
    }
  }
`
const SectionTopDescriptionRight = styled.div`
  font-size: ${props => props.theme.fontSizes[1]}px;
  padding: 0 15px 30px 15px;
  @media (min-width: ${props => props.theme.responsive.small}) {
    padding: 0 45px 30px 15px;
  }
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 50%;
    padding: 30px 30px 0px 0;
    font-size: ${props => props.theme.fontSizes[2]}px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  @media (min-width: ${props => props.theme.responsive.large}) {
    width: 50%;
    padding: 30px 50px 46px 0;
    font-size: ${props => props.theme.fontSizes[2]}px;
  }
`

const ITSelfCheck = styled.div`
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  * {
    color: #fff;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    h2 {
      font-size: ${props => props.theme.fontSizes[4]}px;
      line-height: 1.5em;
    }
  }
`

const ITSCDescription = styled.div`
  padding: 70px 120px 0;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    flex-flow: row nowrap;
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    padding: 60px 80px 0;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding: 30px 15px 0;
  }

  > div:nth-of-type(1) {
    max-width: 100%;
    margin-right: 30px;
  }

  > div:nth-of-type(2) {
    margin-top: 10px;
    max-width: 600px;
  }
`

const ITSCSteps = styled.div`
  padding: 60px 120px 70px;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  overflow: hidden;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      top: calc(50% - 4px);
      z-index: -1;
      background-color: ${props => props.theme.colors.background};
    }
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    padding: 60px 80px 60px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding: 30px 15px 60px;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  div {
    height: 120px;
    width: 120px;
    line-height: 120px;
    border: 2px solid ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.red};
    padding: 0;
    text-align: center;
    font-size: ${props => props.theme.fontSizes[7]}px;
    background-color: ${props => props.theme.colors.text};

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[5]}px;
      height: 80px;
      width: 80px;
      line-height: 80px;
    }

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      height: 60px;
      width: 60px;
      line-height: 60px;
      position: relative;
      &:nth-of-type(1):after {
        content: '';
        display: block;
        width: 101vw;
        height: 2px;
        position: absolute;
        left: 100%;
        top: calc(50% - 1px);
        z-index: -1;
        background-color: ${props => props.theme.colors.background};
      }
    }

    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      height: 50px;
      width: 50px;
      line-height: 50px;
      font-size: ${props => props.theme.fontSizes[4]}px;
      position: relative;
    }
  }

  div:last-of-type {
    height: auto;
    width: auto;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 1em;
    line-height: 1em;

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      width: 100%;
      margin-top: 30px;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 101vw;
        height: 2px;
        position: absolute;
        left: auto;
        right: 50%;
        top: calc(50% - 4px);
        z-index: -1;
        background-color: ${props => props.theme.colors.background};
      }
    }
  }

  a {
    border: 2px solid ${props => props.theme.colors.red};
    border-radius: 999px;
    padding: 7px 20px;
    line-height: 1.3em;
    display: inline-block;
    color: ${props => props.theme.colors.red};
    font-size: ${props => props.theme.fontSizes[5]}px;
    background-color: ${props => props.theme.colors.text};
    opacity: 1 !important;
    &:hover {
      color: ${props => props.theme.colors.text};
      background-color: ${props => props.theme.colors.red};
    }
  }
`

const SectionBlog = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    flex-flow: row wrap;

    h2 {
      font-size: ${props => props.theme.fontSizes[5]}px;
    }
  }
`

const Post = styled.div`
  flex: 1 1 700px;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    flex: 0 1 600px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    flex: 1 1 auto;
  }

  .post-image {
    overflow: hidden;
    border-bottom: 2px solid #000;
    max-width: 100%;
    width: 100%;
    height: 300px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    }
    @media (max-width: ${props => props.theme.responsive.smallMax}) {
    }
  }
`

const PostBottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  flex: 0 1 100%;
`

const PostMeta = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  border-right: 2px solid #000;
  padding: ${props => props.theme.space[2]}px;
  background-color: ${props => props.theme.colors.green};

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    flex-flow: row wrap;
    border-right: none;
    padding: 30px 15px;
  }

  div:first-of-type {
    padding-right: ${props => props.theme.space[2]}px;
    font-size: ${props => props.theme.fontSizes[7]}px;
    line-height: 1.2em;
    flex: 0 1 220px;

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[4]}px;
    }
  }

  div:nth-of-type(2) {
    flex: 2 1 auto;

    h3 {
      font-size: ${props => props.theme.fontSizes[6]}px;
      line-height: 1.25em;
      @media (max-width: ${props => props.theme.responsive.largeMax}) {
        font-size: ${props => props.theme.fontSizes[4]}px;
      }
      @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[3]}px;
      }
    }

    a {
      margin-top: 20px;
      border: 2px solid #fff;
      padding: 3px 20px;
      border-radius: 999px;
      display: inline-block;
      font-size: ${props => props.theme.fontSizes[6]}px;
      line-height: 1.2em;
      opacity: 1 !important;
      &:hover {
        background: ${props => props.theme.colors.background};
      }

      @media (max-width: ${props => props.theme.responsive.largeMax}) {
        font-size: ${props => props.theme.fontSizes[4]}px;
      }
      @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[3]}px;
      }
    }
  }
`

const PostArrows = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 100px;
  padding: 0;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    align-items: flex-start;
    padding: 0 15px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    display: none;
  }

  a {
    display: inline-block;
  }

  svg {
    margin: 5px 5px 15px;

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      margin: 2px 2px 10px;
    }
  }
`

const SectionReferenzen = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  > .referenzenheader {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    background-color: ${props => props.theme.colors.red};
    padding: 60px;
    width: 100%;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      padding: 30px 15px;
    }
    & > h2 {
      max-width: 550px;

      @media (max-width: ${props => props.theme.responsive.largeMax}) {
        font-size: ${props => props.theme.fontSizes[5]}px !important;
      }
      @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[4]}px !important;
      }
      @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[4]}px !important;
      }
    }
  }

  > .referenzenbody {
    background-color: ${props => props.theme.colors.background};
  }
`

const SectionLogos = styled.div`
  width: 100%;
`

const SectionContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  .contentheader {
    background-color: ${props => props.theme.colors.black};
    padding: 60px;
    color: #fff;
    width: 100%;

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      padding: 30px 15px;
    }

    a {
      color: #fff;
      border: 2px solid ${props => props.theme.colors.red};
      border-radius: 999px;
      padding: 7px 15px;
      @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        padding: 3px 15px;
      }
      &:hover {
        background: ${props => props.theme.colors.red};
        color: ${props => props.theme.colors.text};
        opacity: 1 !important;
      }
    }
    > h2 {
      max-width: 650px;
      @media (max-width: ${props => props.theme.responsive.largeMax}) {
        font-size: ${props => props.theme.fontSizes[5]}px !important;
      }
      @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[3]}px !important;
      }
    }
  }

  & .contentsection {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    margin: 0 ${props => props.theme.space[5]}px;

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      margin: 0 ${props => props.theme.space[3]}px;
    }

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      margin: 0 15px;
      border-left: 0;
      border-right: 0;
    }

    h2 {
      padding: ${props => props.theme.space[2]}px;
      border-bottom: 2px solid #000;
      width: 100%;
      font-weight: ${props => props.theme.fontWeights.bold};
      @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        padding: 30px 0;
      }
    }
    h3 {
      text-decoration: underline;
      margin-bottom: 6px;
    }
  }

  & .content-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-bottom: 2px solid #000;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      flex-flow: row wrap;
      border-bottom: none;
      &:last-of-type .content-group:last-of-type div {
        border-bottom: none !important;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    & .content-group {
      &:first-of-type {
        border-right: 2px solid #000;
        @media (max-width: ${props => props.theme.responsive.mediumMax}) {
          border-right: none;
        }
      }
      width: 100%;

      @media (min-width: ${props => props.theme.responsive.medium}) {
        max-width: 50%;
      }
      > div {
        padding: ${props => props.theme.space[2]}px;

        @media (max-width: ${props => props.theme.responsive.mediumMax}) {
          padding: 30px 0px;
          border-bottom: 2px solid #000;
        }
      }
    }
    ul {
      padding-left: 20px;
      margin-bottom: 12px;
    }
    ul,
    li {
      list-style-type: circle;
      list-style-position: outside;
    }
  }
`

const HomepageContent = props => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomepage {
        headline {
          headline
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        headline2 {
          childMarkdownRemark {
            html
          }
        }
        textarea2 {
          childMarkdownRemark {
            html
          }
        }
      }
      allContentfulPost(sort: { order: DESC, fields: publishDate }, limit: 7) {
        edges {
          node {
            title
            publishDate(formatString: "MM/YY")
            slug
            heroImage {
              title
              fluid(maxWidth: 1400, maxHeight: 600, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      allContentfulLeistungen(
        filter: { featured: { eq: true } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            title
            slug
            internal {
              type
            }
          }
        }
      }
      allContentfulProdukte(
        filter: { featured: { eq: true } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            title
            slug
            internal {
              type
            }
          }
        }
      }
    }
  `)

  const arrayLeistungen = data.allContentfulLeistungen.edges
  const arrayProdukte = data.allContentfulProdukte.edges
  const amount = Math.min(arrayLeistungen.length, arrayProdukte.length)
  let combined = []

  for (let i = 0; i < amount; i++) {
    combined.push(arrayLeistungen[i], arrayProdukte[i])
  }
  combined.push(
    ...arrayLeistungen.slice(amount),
    ...arrayProdukte.slice(amount)
  )

  return (
    <Content>
      <SectionTop>
        <SectionTopLinklist>
          {combined.map(({ node }) => (
            <Link
              key={`linklist-${node.slug}`}
              to={
                node.internal.type === 'ContentfulProdukte'
                  ? `/produkte/${node.slug}/`
                  : `/leistungen/${node.slug}/`
              }
            >
              <div>{node.title}</div>
            </Link>
          ))}
        </SectionTopLinklist>
        <SectionTopDescription>
          <SectionTopDescriptionLeft css={blocks}>
            <h2
              css={css`
                white-space: pre-wrap;
              `}
            >
              {data.contentfulHomepage.headline.headline}
            </h2>
            <Link to={`/termine/`}>Termin vereinbaren</Link>
          </SectionTopDescriptionLeft>
          <SectionTopDescriptionRight
            css={blocks}
            dangerouslySetInnerHTML={{
              __html:
                data.contentfulHomepage.description.childMarkdownRemark.html,
            }}
          />

          <a
            onClick={() => scrollTo('#s1')}
            href="#scrollto"
            className="ark-arrow"
          >
            <Arrow />
          </a>
        </SectionTopDescription>
      </SectionTop>
      <ITSelfCheck id="s1">
        <ITSCDescription>
          <div>
            <Styled.h2>
              5 einfache Fragen.
              <br />
              Viele Antworten.
              <br />
              Der IT-Self-Check.
            </Styled.h2>
          </div>
          <div>
            Haben Sie eine Übersicht über die Sicherheit Ihrer IT-Infrastruktur?
            <br />
            Finden Sie in unserem IT-Check heraus, ob Ihre IT Ihren Bedürfnissen
            genügt. Und vor allem: Ob sie auch künftig weiter funktionieren
            wird.
            <br />
            <strong>Dauer: 1 Minute</strong>
          </div>
        </ITSCDescription>
        <ITSCSteps>
          <div>01</div>
          <div>02</div>
          <div>03</div>
          <div>04</div>
          <div>05</div>
          <div>
            <Link to="/it-selfcheck/">Jetzt checken</Link>
          </div>
        </ITSCSteps>
      </ITSelfCheck>
      <SectionBlog>
        <ContainerLeftTitle>Blog</ContainerLeftTitle>
        <PostSlider />
        <Post>
          <div className="post-image">
            <Img
              fluid={data.allContentfulPost.edges[0].node.heroImage.fluid}
              alt={data.allContentfulPost.edges[0].node.heroImage.title}
              css={css`
                height: 300px;
                width: auto;
                margin: 0 auto; 
              `}
            />
          </div>
          <PostBottom>
            <PostMeta>
              <div css={css`
              word-break: break-all;
              `}>{data.allContentfulPost.edges[0].node.publishDate}</div>
              <div>
                <Styled.h3>
                  {data.allContentfulPost.edges[0].node.title}
                </Styled.h3>
                <Link
                  to={`/news/${data.allContentfulPost.edges[0].node.slug}/`}
                >
                  Jetzt lesen
                </Link>
              </div>
            </PostMeta>
            <PostArrows>
              <a onClick={() => scrollTo('#s2')} href="#scrollto">
                <Arrow />
                <Arrow />
                <Arrow />
              </a>
            </PostArrows>
          </PostBottom>
        </Post>
      </SectionBlog>
      <SectionReferenzen id="s2">
        <div className="referenzenheader">
          <Styled.h2>
            Was bestehende Kundinnen und Kunden über uns sagen:
          </Styled.h2>
        </div>
        <div className="referenzenbody">
          <Referenzen />
        </div>
      </SectionReferenzen>
      <SectionLogos>
        <Partners />
      </SectionLogos>
      <SectionContent>
        <div className="contentheader">
          <Styled.h2
            dangerouslySetInnerHTML={{
              __html:
                data.contentfulHomepage.headline2.childMarkdownRemark.html,
            }}
          />
        </div>
        <div
          className="contentsection"
          dangerouslySetInnerHTML={{
            __html: data.contentfulHomepage.textarea2.childMarkdownRemark.html,
          }}
        />
      </SectionContent>
    </Content>
  )
}

export default HomepageContent
